

.main_bg {
  background-color: #343a40;
  height: 100vh;
  width: 100vw;
}

footer {
  color: lightGrey;
  width: 100vw;
  text-align: center;
  margin-top: -10vh;
  height: 10vh;
  position: relative;
}

body {
  overflow-y: hidden;
}
