/* mobile small (iphone se) */
/* portrait */
@media screen and (max-width: 350px) and (orientation: portrait){
  .mainHeader {
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 20px;
    padding: 0;
    padding-right: 30px;
    background-color: rgba(56,62,66, 0.8);
  }

  .mainHeader .container{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 540px;
  }

  .card {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    alignItems: center;
    justifyContent: center;
    margin-left: 0;
  }

  .video_main {
    width: auto;
    height: 110vh;
    
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 1;
    overflow-y:  hidden;
  }

  .bgImage_main {
    width: auto;
    height: 110vh;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 0;
  }

  .video_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
    background-color: rgba(0,0,0,0.4)
  }

  .img_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
  }


  .title_video {
      text-align: center;
      position: relative;
      padding-left: 22%;
      padding-right: 10%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top: 25vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.5em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2);
      vertical-align:middle
  }

  .bgImage {
    height: 100vh;
    width: 100vw;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
    filter: brightness(50%);
    overflow-y:  hidden;
  }

  .desc_overlay {
    background-color: #212529;
    opacity: 0.8;
    position: relative;
    left: 20%;
    top: 20vh;
    width:70%;
    height:70vh;
    border-radius: 5%;
  }

  .desc_overlay p{
    position: relative;
    padding: 5%;
    margin: 10px;
    color: white;
    font-size: 0.9em;
  }

  .title_keyfacts {
      text-align: center;
      position: relative;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top:18vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.7em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
          1px 2px 1px #919191,
          1px 2.5px 1px #919191,
          1px 3px 1px #919191,
          1px 3.5px 1px #919191,
          1px 4px 1px #919191,
          1px 4.5px 1px #919191,
          1px 5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2),
      1px 12px 17px rgba(16,16,16,0.2),
      1px 15px 30px rgba(16,16,16,0.4);
      vertical-align:middle
  }

  .key-facts {
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 1em;
    font-weight: 200;
    color: #f5f5f5;
    max-width: 70vw;
    margin-left: 25vw;
    margin-top: 20vh;
  }

  .key-facts h3{
      text-align: left;
      position: relative;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.2em;
      font-weight: 700;
      color: #f5f5f5;
      vertical-align:middle
  }

  .key-facts p{
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 0.7em;
    font-weight: 100;
    color: #f5f5f5;
    max-width: 70vw;
    margin-top: 10px;
  }

  .logo {
    filter: brightness(200%);
    padding: 0px;
    height: 8vh;
    min-height: 50px;
  }

  .nav-link {
    font-size: 1.4em;
  }

  .nav-link.active {
    color: orange;
  }

  .navIndicator {
    position:fixed;
    height: 100vh;
    width: 25vw;
    top:0;
    z-index: 200;
  }

  .navIndicator-container {
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);

  }

  .nav-dot {
    width: 100%;
    top:50%;
    bottom: 50%;
    height: 50px;
    padding-left: 5%;
    padding-right: 5%;
    align-content: center;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .navdot-dot {
    background-color: white;
    width: 20px;
    height:20px;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    left: 30px;
    transition: all 0.1s ease-in-out;
  }

  .navdot-title {
    display: none;
    margin-left: 80px;
    text-transform: uppercase;
    font-family: verdana;
    font-size: 1.5em;
    font-weight: 350;
    color: #f5f5f5;

    text-shadow: 1px 0.5px 1px #919191,
        1px 1px 1px #919191,
        1px 1.5px 1px #919191,
        1px 2px 1px #919191,
        1px 2.5px 1px #919191,
        1px 3px 1px #919191;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    white-space: nowrap;
  }


  .active-dot{
    width: 30px;
    transition: all 0.1s ease-in-out;
  }

  footer {
    color: lightGrey;
    width: 100vw;
    text-align: center;
    margin-top: -10vh;
    height: 10vh;
    position: relative;
    font-size: 1em;
  }
}

/* landscape */
@media screen and (max-width: 568px) and (orientation: landscape){
  .mainHeader {
    margin-top: 3%;
    margin-left: 20%;
    margin-right: 5%;
    border-radius: 20px;
    padding: 0;
    padding-right: 30px;
    background-color: rgba(56,62,66, 0.8);
  }

  .mainHeader .container{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 540px;
  }

  

  .card {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    alignItems: center;
    justifyContent: center;
    margin-left: 0;
  }

  .video_main {
    width: auto;
    height: 110vh;
    
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 1;
    overflow-y:  hidden;
  }
  
  .bgImage_main {
    width: auto;
    height: 110vh;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 0;
  }

  .video_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
    background-color: rgba(0,0,0,0.4)
  }

  .img_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
  }


  .title_video {
      text-align: center;
      position: relative;
      padding-left: 22%;
      padding-right: 10%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top: 30vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.5em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2);
      vertical-align:middle
  }

  .bgImage {
    height: 100vh;
    width: 100vw;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
    filter: brightness(50%);
    overflow-y:  hidden;
  }

  .desc_overlay {
    background-color: #212529;
    opacity: 0.8;
    position: relative;
    left: 20%;
    top: 30vh;
    width:75%;
    height:60vh;
    border-radius: 5%;
  }

  .desc_overlay p{
    position: relative;
    padding: 5%;
    margin: 10px;
    color: white;
    font-size: 0.7em;
  }

  .title_keyfacts {
      text-align: center;
      position: relative;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top:18vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.7em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
          1px 2px 1px #919191,
          1px 2.5px 1px #919191,
          1px 3px 1px #919191,
          1px 3.5px 1px #919191,
          1px 4px 1px #919191,
          1px 4.5px 1px #919191,
          1px 5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2),
      1px 12px 17px rgba(16,16,16,0.2),
      1px 15px 30px rgba(16,16,16,0.4);
      vertical-align:middle
  }

  .key-facts {
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 1em;
    font-weight: 200;
    color: #f5f5f5;
    max-width: 70vw;
    margin-left: 25vw;
    margin-top: 32vh;
  }

  .key-facts h3{
      text-align: left;
      position: relative;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 0.8em;
      font-weight: 700;
      color: #f5f5f5;
      vertical-align:middle
  }

  .key-facts p{
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 0.6em;
    font-weight: 100;
    color: #f5f5f5;
    max-width: 70vw;
    margin-top: -5px;
  }

  .logo {
    filter: brightness(200%);
    padding: 0px;
    height: 8vh;
    min-height: 50px;
  }

  .nav-link {
    font-size: 1.4em;
  }

  .nav-link.active {
    color: orange;
  }

  .navIndicator {
    position:fixed;
    height: 100vh;
    width: 25vw;
    top:0;
    z-index: 200;
  }

  .navIndicator-container {
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);

  }

  .nav-dot {
    width: 100%;
    top:50%;
    bottom: 50%;
    height: 50px;
    padding-left: 5%;
    padding-right: 5%;
    align-content: center;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .navdot-dot {
    background-color: white;
    width: 20px;
    height:20px;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    left: 30px;
    transition: all 0.1s ease-in-out;
  }

  .navdot-title {
    display: none;
    margin-left: 80px;
    text-transform: uppercase;
    font-family: verdana;
    font-size: 1.5em;
    font-weight: 350;
    color: #f5f5f5;

    text-shadow: 1px 0.5px 1px #919191,
        1px 1px 1px #919191,
        1px 1.5px 1px #919191,
        1px 2px 1px #919191,
        1px 2.5px 1px #919191,
        1px 3px 1px #919191;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    white-space: nowrap;
  }


  .active-dot{
    width: 30px;
    transition: all 0.1s ease-in-out;
  }

  footer {
    color: lightGrey;
    width: 100vw;
    text-align: center;
    margin-top: -10vh;
    height: 10vh;
    position: relative;
    font-size: 1em;
  }
}

/* mobile medium (iphone 8) */
/* portrait */
@media screen and (min-width: 350px) and (max-width: 390px) and (orientation: portrait){
  .mainHeader {
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 20px;
    padding: 0;
    padding-right: 30px;
    background-color: rgba(56,62,66, 0.8);
  }

  .mainHeader .container{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 540px;
  }

  .card {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    alignItems: center;
    justifyContent: center;
    margin-left: 0;
  }

  .video_main {
    width: auto;
    height: 110vh;
    
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 1;
    overflow-y:  hidden;
  }

  .bgImage_main {
    width: auto;
    height: 110vh;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 0;
  }

  .video_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
    background-color: rgba(0,0,0,0.4)
  }

  .img_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
  }


  .title_video {
      text-align: center;
      position: relative;
      padding-left: 22%;
      padding-right: 10%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top: 30vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.8em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2);
      vertical-align:middle
  }

  .bgImage {
    height: 100vh;
    width: 100vw;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
    filter: brightness(50%);
    overflow-y:  hidden;
  }

  .desc_overlay {
    background-color: #212529;
    opacity: 0.8;
    position: relative;
    left: 20%;
    top: 20vh;
    width:70%;
    height:70vh;
    border-radius: 5%;
  }

  .desc_overlay p{
    position: relative;
    padding: 5%;
    margin: 10px;
    color: white;
    font-size: 1em;
  }

  .title_keyfacts {
      text-align: center;
      position: relative;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top:18vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.9em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
          1px 2px 1px #919191,
          1px 2.5px 1px #919191,
          1px 3px 1px #919191,
          1px 3.5px 1px #919191,
          1px 4px 1px #919191,
          1px 4.5px 1px #919191,
          1px 5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2),
      1px 12px 17px rgba(16,16,16,0.2),
      1px 15px 30px rgba(16,16,16,0.4);
      vertical-align:middle
  }

  .key-facts {
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 1em;
    font-weight: 200;
    color: #f5f5f5;
    max-width: 70vw;
    margin-left: 25vw;
    margin-top: 20vh;
  }

  .key-facts h3{
      text-align: left;
      position: relative;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.4em;
      font-weight: 700;
      color: #f5f5f5;
      vertical-align:middle
  }

  .key-facts p{
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 0.8em;
    font-weight: 100;
    color: #f5f5f5;
    max-width: 70vw;
    margin-top: 10px;
  }

  .logo {
    filter: brightness(200%);
    padding: 0px;
    height: 8vh;
    min-height: 50px;
  }

  .nav-link {
    font-size: 1.4em;
  }

  .nav-link.active {
    color: orange;
  }

  .navIndicator {
    position:fixed;
    height: 100vh;
    width: 25vw;
    top:0;
    z-index: 200;
  }

  .navIndicator-container {
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);

  }

  .nav-dot {
    width: 100%;
    top:50%;
    bottom: 50%;
    height: 50px;
    padding-left: 5%;
    padding-right: 5%;
    align-content: center;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .navdot-dot {
    background-color: white;
    width: 20px;
    height:20px;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    left: 30px;
    transition: all 0.1s ease-in-out;
  }

  .navdot-title {
    display: none;
    margin-left: 80px;
    text-transform: uppercase;
    font-family: verdana;
    font-size: 1.5em;
    font-weight: 350;
    color: #f5f5f5;

    text-shadow: 1px 0.5px 1px #919191,
        1px 1px 1px #919191,
        1px 1.5px 1px #919191,
        1px 2px 1px #919191,
        1px 2.5px 1px #919191,
        1px 3px 1px #919191;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    white-space: nowrap;
  }


  .active-dot{
    width: 30px;
    transition: all 0.1s ease-in-out;
  }

  footer {
    color: lightGrey;
    width: 100vw;
    text-align: center;
    margin-top: -10vh;
    height: 10vh;
    position: relative;
    font-size: 1em;
  }
}

/* landscape */
@media screen and (min-width: 568px) and (max-width: 680px) and (orientation: landscape){
  .mainHeader {
    margin-top: 3%;
    margin-left: 20%;
    margin-right: 5%;
    border-radius: 20px;
    padding: 0;
    padding-right: 30px;
    background-color: rgba(56,62,66, 0.8);
  }

  .mainHeader .container{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 540px;
  }

  

  .card {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    alignItems: center;
    justifyContent: center;
    margin-left: 0;
  }

  .video_main {
    width: auto;
    height: 110vh;
    
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 1;
    overflow-y:  hidden;
  }
  
  .bgImage_main {
    width: auto;
    height: 110vh;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 0;
  }

  .video_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
    background-color: rgba(0,0,0,0.4)
  }

  .img_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
  }


  .title_video {
      text-align: center;
      position: relative;
      padding-left: 22%;
      padding-right: 10%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top: 30vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.5em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2);
      vertical-align:middle
  }

  .bgImage {
    height: 100vh;
    width: 100vw;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
    filter: brightness(50%);
    overflow-y:  hidden;
  }

  .desc_overlay {
    background-color: #212529;
    opacity: 0.8;
    position: relative;
    left: 20%;
    top: 30vh;
    width:75%;
    height:60vh;
    border-radius: 5%;
  }

  .desc_overlay p{
    position: relative;
    padding: 5%;
    margin: 10px;
    color: white;
    font-size: 0.8em;
  }

  .title_keyfacts {
      text-align: center;
      position: relative;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top:18vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.7em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
          1px 2px 1px #919191,
          1px 2.5px 1px #919191,
          1px 3px 1px #919191,
          1px 3.5px 1px #919191,
          1px 4px 1px #919191,
          1px 4.5px 1px #919191,
          1px 5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2),
      1px 12px 17px rgba(16,16,16,0.2),
      1px 15px 30px rgba(16,16,16,0.4);
      vertical-align:middle
  }

  .key-facts {
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 1em;
    font-weight: 200;
    color: #f5f5f5;
    max-width: 70vw;
    margin-left: 25vw;
    margin-top: 32vh;
  }

  .key-facts h3{
      text-align: left;
      position: relative;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 0.8em;
      font-weight: 700;
      color: #f5f5f5;
      vertical-align:middle
  }

  .key-facts p{
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 0.6em;
    font-weight: 100;
    color: #f5f5f5;
    max-width: 70vw;
    margin-top: -5px;
  }

  .logo {
    filter: brightness(200%);
    padding: 0px;
    height: 8vh;
    min-height: 50px;
  }

  .nav-link {
    font-size: 1.4em;
  }

  .nav-link.active {
    color: orange;
  }

  .navIndicator {
    position:fixed;
    height: 100vh;
    width: 25vw;
    top:0;
    z-index: 200;
  }

  .navIndicator-container {
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);

  }

  .nav-dot {
    width: 100%;
    top:50%;
    bottom: 50%;
    height: 50px;
    padding-left: 5%;
    padding-right: 5%;
    align-content: center;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .navdot-dot {
    background-color: white;
    width: 20px;
    height:20px;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    left: 30px;
    transition: all 0.1s ease-in-out;
  }

  .navdot-title {
    display: none;
    margin-left: 80px;
    text-transform: uppercase;
    font-family: verdana;
    font-size: 1.5em;
    font-weight: 350;
    color: #f5f5f5;

    text-shadow: 1px 0.5px 1px #919191,
        1px 1px 1px #919191,
        1px 1.5px 1px #919191,
        1px 2px 1px #919191,
        1px 2.5px 1px #919191,
        1px 3px 1px #919191;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    white-space: nowrap;
  }


  .active-dot{
    width: 30px;
    transition: all 0.1s ease-in-out;
  }

  footer {
    color: lightGrey;
    width: 100vw;
    text-align: center;
    margin-top: -10vh;
    height: 10vh;
    position: relative;
    font-size: 1em;
  }
}

/* mobile large (iphone 8 plus) */
/* portrait */
@media screen and (min-width: 390px) and (max-width: 420px) and (orientation: portrait){
  .mainHeader {
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 20px;
    padding: 0;
    padding-right: 30px;
    background-color: rgba(56,62,66, 0.8);
  }

  .mainHeader .container{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 540px;
  }

  .card {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    alignItems: center;
    justifyContent: center;
    margin-left: 0;
  }

  .video_main {
    width: auto;
    height: 110vh;
    
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 1;
    overflow-y:  hidden;
  }

  .bgImage_main {
    width: auto;
    height: 110vh;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 0;
  }

  .video_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
    background-color: rgba(0,0,0,0.4)
  }

  .img_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
  }


  .title_video {
      text-align: center;
      position: relative;
      padding-left: 22%;
      padding-right: 10%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top: 25vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 2em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2);
      vertical-align:middle
  }

  .bgImage {
    height: 100vh;
    width: 100vw;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
    filter: brightness(50%);
    overflow-y:  hidden;
  }

  .desc_overlay {
    background-color: #212529;
    opacity: 0.8;
    position: relative;
    left: 20%;
    top: 20vh;
    width:70%;
    height:70vh;
    border-radius: 5%;
  }

  .desc_overlay p{
    position: relative;
    padding: 5%;
    margin: 10px;
    color: white;
    font-size: 1.1em;
  }

  .title_keyfacts {
      text-align: center;
      position: relative;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top:18vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.7em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
          1px 2px 1px #919191,
          1px 2.5px 1px #919191,
          1px 3px 1px #919191,
          1px 3.5px 1px #919191,
          1px 4px 1px #919191,
          1px 4.5px 1px #919191,
          1px 5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2),
      1px 12px 17px rgba(16,16,16,0.2),
      1px 15px 30px rgba(16,16,16,0.4);
      vertical-align:middle
  }

  .key-facts {
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 1em;
    font-weight: 200;
    color: #f5f5f5;
    max-width: 70vw;
    margin-left: 25vw;
    margin-top: 20vh;
  }

  .key-facts h3{
      text-align: left;
      position: relative;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.4em;
      font-weight: 700;
      color: #f5f5f5;
      vertical-align:middle
  }

  .key-facts p{
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 0.9em;
    font-weight: 100;
    color: #f5f5f5;
    max-width: 70vw;
    margin-top: 10px;
  }

  .logo {
    filter: brightness(200%);
    padding: 0px;
    height: 8vh;
    min-height: 50px;
  }

  .nav-link {
    font-size: 1.4em;
  }

  .nav-link.active {
    color: orange;
  }

  .navIndicator {
    position:fixed;
    height: 100vh;
    width: 25vw;
    top:0;
    z-index: 200;
  }

  .navIndicator-container {
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);

  }

  .nav-dot {
    width: 100%;
    top:50%;
    bottom: 50%;
    height: 50px;
    padding-left: 5%;
    padding-right: 5%;
    align-content: center;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .navdot-dot {
    background-color: white;
    width: 20px;
    height:20px;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    left: 30px;
    transition: all 0.1s ease-in-out;
  }

  .navdot-title {
    display: none;
    margin-left: 80px;
    text-transform: uppercase;
    font-family: verdana;
    font-size: 1.5em;
    font-weight: 350;
    color: #f5f5f5;

    text-shadow: 1px 0.5px 1px #919191,
        1px 1px 1px #919191,
        1px 1.5px 1px #919191,
        1px 2px 1px #919191,
        1px 2.5px 1px #919191,
        1px 3px 1px #919191;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    white-space: nowrap;
  }


  .active-dot{
    width: 30px;
    transition: all 0.1s ease-in-out;
  }

  footer {
    color: lightGrey;
    width: 100vw;
    text-align: center;
    margin-top: -10vh;
    height: 10vh;
    position: relative;
    font-size: 1em;
  }
}

/* landscape */
@media screen and (min-width: 680px) and (max-width: 860px) and (orientation: landscape){
  .mainHeader {
    margin-top: 3%;
    margin-left: 20%;
    margin-right: 5%;
    border-radius: 20px;
    padding: 0;
    padding-right: 30px;
    background-color: rgba(56,62,66, 0.8);
  }

  .mainHeader .container{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 540px;
  }

  

  .card {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    alignItems: center;
    justifyContent: center;
    margin-left: 0;
  }

  .video_main {
    width: auto;
    height: 110vh;
    
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 1;
    overflow-y:  hidden;
  }
  
  .bgImage_main {
    width: auto;
    height: 110vh;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 0;
  }

  .video_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
    background-color: rgba(0,0,0,0.4)
  }

  .img_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
  }


  .title_video {
      text-align: center;
      position: relative;
      padding-left: 22%;
      padding-right: 10%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top: 30vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.5em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2);
      vertical-align:middle
  }

  .bgImage {
    height: 100vh;
    width: 100vw;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
    filter: brightness(50%);
    overflow-y:  hidden;
  }

  .desc_overlay {
    background-color: #212529;
    opacity: 0.8;
    position: relative;
    left: 20%;
    top: 30vh;
    width:75%;
    height:60vh;
    border-radius: 5%;
  }

  .desc_overlay p{
    position: relative;
    padding: 5%;
    margin: 10px;
    color: white;
    font-size: 0.9em;
  }

  .title_keyfacts {
      text-align: center;
      position: relative;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top:18vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.7em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
          1px 2px 1px #919191,
          1px 2.5px 1px #919191,
          1px 3px 1px #919191,
          1px 3.5px 1px #919191,
          1px 4px 1px #919191,
          1px 4.5px 1px #919191,
          1px 5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2),
      1px 12px 17px rgba(16,16,16,0.2),
      1px 15px 30px rgba(16,16,16,0.4);
      vertical-align:middle
  }

  .key-facts {
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 1em;
    font-weight: 200;
    color: #f5f5f5;
    max-width: 70vw;
    margin-left: 25vw;
    margin-top: 32vh;
  }

  .key-facts h3{
      text-align: left;
      position: relative;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 0.9em;
      font-weight: 700;
      color: #f5f5f5;
      vertical-align:middle
  }

  .key-facts p{
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 0.7em;
    font-weight: 100;
    color: #f5f5f5;
    max-width: 70vw;
    margin-top: -5px;
  }

  .logo {
    filter: brightness(200%);
    padding: 0px;
    height: 8vh;
    min-height: 50px;
  }

  .nav-link {
    font-size: 1.4em;
  }

  .nav-link.active {
    color: orange;
  }

  .navIndicator {
    position:fixed;
    height: 100vh;
    width: 25vw;
    top:0;
    z-index: 200;
  }

  .navIndicator-container {
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);

  }

  .nav-dot {
    width: 100%;
    top:50%;
    bottom: 50%;
    height: 50px;
    padding-left: 5%;
    padding-right: 5%;
    align-content: center;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .navdot-dot {
    background-color: white;
    width: 20px;
    height:20px;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    left: 30px;
    transition: all 0.1s ease-in-out;
  }

  .navdot-title {
    display: none;
    margin-left: 80px;
    text-transform: uppercase;
    font-family: verdana;
    font-size: 1.5em;
    font-weight: 350;
    color: #f5f5f5;

    text-shadow: 1px 0.5px 1px #919191,
        1px 1px 1px #919191,
        1px 1.5px 1px #919191,
        1px 2px 1px #919191,
        1px 2.5px 1px #919191,
        1px 3px 1px #919191;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    white-space: nowrap;
  }


  .active-dot{
    width: 30px;
    transition: all 0.1s ease-in-out;
  }

  footer {
    color: lightGrey;
    width: 100vw;
    text-align: center;
    margin-top: -10vh;
    height: 10vh;
    position: relative;
    font-size: 1em;
  }
}


/* tablet */
/* portrait */
@media screen and (min-width: 420px) and (max-width: 780px) and (orientation: portrait){
  .mainHeader {
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 20px;
    padding: 0;
    padding-right: 30px;
    background-color: rgba(56,62,66, 0.8);
  }

  .mainHeader .container{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 540px;
  }

  .card {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    alignItems: center;
    justifyContent: center;
    margin-left: 0;
  }

  .video_main {
    width: auto;
    height: 110vh;
    
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 1;
    overflow-y:  hidden;
  }

  .bgImage_main {
    width: auto;
    height: 110vh;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 0;
  }

  .video_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
    background-color: rgba(0,0,0,0.4)
  }

  .img_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
  }


  .title_video {
      text-align: center;
      position: relative;
      padding-left: 22%;
      padding-right: 10%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top: 25vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 3em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2);
      vertical-align:middle
  }

  .bgImage {
    height: 100vh;
    width: 100vw;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
    filter: brightness(50%);
    overflow-y:  hidden;
  }

  .desc_overlay {
    background-color: #212529;
    opacity: 0.8;
    position: relative;
    left: 20%;
    top: 20vh;
    width:70%;
    height:70vh;
    border-radius: 5%;
  }

  .desc_overlay p{
    position: relative;
    padding: 5%;
    margin: 10px;
    color: white;
    font-size: 1.8em;
  }

  .title_keyfacts {
      text-align: center;
      position: relative;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top:18vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.7em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
          1px 2px 1px #919191,
          1px 2.5px 1px #919191,
          1px 3px 1px #919191,
          1px 3.5px 1px #919191,
          1px 4px 1px #919191,
          1px 4.5px 1px #919191,
          1px 5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2),
      1px 12px 17px rgba(16,16,16,0.2),
      1px 15px 30px rgba(16,16,16,0.4);
      vertical-align:middle
  }

  .key-facts {
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 1em;
    font-weight: 200;
    color: #f5f5f5;
    max-width: 70vw;
    margin-left: 25vw;
    margin-top: 20vh;
  }

  .key-facts h3{
      text-align: left;
      position: relative;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 2em;
      font-weight: 700;
      color: #f5f5f5;
      vertical-align:middle;
      padding-top: 2vh;
  }

  .key-facts p{
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 1.5em;
    font-weight: 100;
    color: #f5f5f5;
    max-width: 70vw;
    margin-top: 10px;
  }

  .logo {
    filter: brightness(200%);
    padding: 0px;
    height: 8vh;
    min-height: 50px;
  }

  .nav-link {
    font-size: 1.4em;
  }

  .nav-link.active {
    color: orange;
  }

  .navIndicator {
    position:fixed;
    height: 100vh;
    width: 25vw;
    top:0;
    z-index: 200;
  }

  .navIndicator-container {
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);

  }

  .nav-dot {
    width: 100%;
    top:50%;
    bottom: 50%;
    height: 50px;
    padding-left: 5%;
    padding-right: 5%;
    align-content: center;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .navdot-dot {
    background-color: white;
    width: 20px;
    height:20px;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    left: 30px;
    transition: all 0.1s ease-in-out;
  }

  .navdot-title {
    display: none;
    margin-left: 80px;
    text-transform: uppercase;
    font-family: verdana;
    font-size: 1.5em;
    font-weight: 350;
    color: #f5f5f5;

    text-shadow: 1px 0.5px 1px #919191,
        1px 1px 1px #919191,
        1px 1.5px 1px #919191,
        1px 2px 1px #919191,
        1px 2.5px 1px #919191,
        1px 3px 1px #919191;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    white-space: nowrap;
  }


  .active-dot{
    width: 30px;
    transition: all 0.1s ease-in-out;
  }

  footer {
    color: lightGrey;
    width: 100vw;
    text-align: center;
    margin-top: -10vh;
    height: 10vh;
    position: relative;
    font-size: 1em;
  }
}

/* landscape */
@media screen and (min-width: 860px) and (max-width: 1024px) and (orientation: landscape){
  .mainHeader {
    margin-top: 3%;
    margin-left: 20%;
    margin-right: 5%;
    border-radius: 20px;
    padding: 0;
    padding-right: 30px;
    background-color: rgba(56,62,66, 0.8);
  }

  .mainHeader .container{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 540px;
  }

  

  .card {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    alignItems: center;
    justifyContent: center;
    margin-left: 0;
  }

  .video_main {
    width: auto;
    height: 110vh;
    
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 1;
    overflow-y:  hidden;
  }
  
  .bgImage_main {
    width: auto;
    height: 110vh;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 0;
  }

  .video_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
    background-color: rgba(0,0,0,0.4)
  }

  .img_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
  }


  .title_video {
      text-align: center;
      position: relative;
      padding-left: 22%;
      padding-right: 10%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top: 30vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 2.5em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2);
      vertical-align:middle
  }

  .bgImage {
    height: 100vh;
    width: 100vw;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
    filter: brightness(50%);
    overflow-y:  hidden;
  }

  .desc_overlay {
    background-color: #212529;
    opacity: 0.8;
    position: relative;
    left: 20%;
    top: 30vh;
    width:75%;
    height:60vh;
    border-radius: 5%;
  }

  .desc_overlay p{
    position: relative;
    padding: 5%;
    margin: 10px;
    color: white;
    font-size: 1.5em;
  }

  .title_keyfacts {
      text-align: center;
      position: relative;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top:18vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.7em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
          1px 2px 1px #919191,
          1px 2.5px 1px #919191,
          1px 3px 1px #919191,
          1px 3.5px 1px #919191,
          1px 4px 1px #919191,
          1px 4.5px 1px #919191,
          1px 5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2),
      1px 12px 17px rgba(16,16,16,0.2),
      1px 15px 30px rgba(16,16,16,0.4);
      vertical-align:middle
  }

  .key-facts {
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 1em;
    font-weight: 200;
    color: #f5f5f5;
    max-width: 70vw;
    margin-left: 25vw;
    margin-top: 32vh;
  }

  .key-facts h3{
      text-align: left;
      position: relative;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.8em;
      font-weight: 700;
      color: #f5f5f5;
      vertical-align:middle
  }

  .key-facts p{
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 1.2em;
    font-weight: 100;
    color: #f5f5f5;
    max-width: 70vw;
    margin-top: -5px;
  }

  .logo {
    filter: brightness(200%);
    padding: 0px;
    height: 8vh;
    min-height: 50px;
  }

  .nav-link {
    font-size: 1.4em;
  }

  .nav-link.active {
    color: orange;
  }

  .navIndicator {
    position:fixed;
    height: 100vh;
    width: 25vw;
    top:0;
    z-index: 200;
  }

  .navIndicator-container {
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);

  }

  .nav-dot {
    width: 100%;
    top:50%;
    bottom: 50%;
    height: 50px;
    padding-left: 5%;
    padding-right: 5%;
    align-content: center;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .navdot-dot {
    background-color: white;
    width: 20px;
    height:20px;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    left: 30px;
    transition: all 0.1s ease-in-out;
  }

  .navdot-title {
    display: none;
    margin-left: 80px;
    text-transform: uppercase;
    font-family: verdana;
    font-size: 1.5em;
    font-weight: 350;
    color: #f5f5f5;

    text-shadow: 1px 0.5px 1px #919191,
        1px 1px 1px #919191,
        1px 1.5px 1px #919191,
        1px 2px 1px #919191,
        1px 2.5px 1px #919191,
        1px 3px 1px #919191;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    white-space: nowrap;
  }


  .active-dot{
    width: 30px;
    transition: all 0.1s ease-in-out;
  }

  footer {
    color: lightGrey;
    width: 100vw;
    text-align: center;
    margin-top: -10vh;
    height: 10vh;
    position: relative;
    font-size: 1em;
  }
}

/* computer*/
/* portrait */
@media screen and (min-width: 780px) and (orientation: portrait){
  .mainHeader {
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 20px;
    padding: 0;
    padding-right: 30px;
    background-color: rgba(56,62,66, 0.8);
  }

  .mainHeader .container{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 540px;
  }

  .card {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    alignItems: center;
    justifyContent: center;
    margin-left: 0;
  }

  .video_main {
    width: auto;
    height: 110vh;
    
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 1;
    overflow-y:  hidden;
  }

  .bgImage_main {
    width: auto;
    height: 110vh;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 0;
  }

  .video_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
    background-color: rgba(0,0,0,0.4)
  }

  .img_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
  }


  .title_video {
      text-align: center;
      position: relative;
      padding-left: 22%;
      padding-right: 10%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top: 25vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 5vh;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2);
      vertical-align:middle
  }

  .bgImage {
    height: 100vh;
    width: 100vw;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
    filter: brightness(50%);
    overflow-y:  hidden;
  }

  .desc_overlay {
    background-color: #212529;
    opacity: 0.8;
    position: relative;
    left: 20%;
    top: 20vh;
    width:70%;
    height:70vh;
    border-radius: 5%;
  }

  .desc_overlay p{
    position: relative;
    padding: 5%;
    margin: 10px;
    color: white;
    font-size: 2.7vh;
  }

  .title_keyfacts {
      text-align: center;
      position: relative;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top:18vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.7em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
          1px 2px 1px #919191,
          1px 2.5px 1px #919191,
          1px 3px 1px #919191,
          1px 3.5px 1px #919191,
          1px 4px 1px #919191,
          1px 4.5px 1px #919191,
          1px 5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2),
      1px 12px 17px rgba(16,16,16,0.2),
      1px 15px 30px rgba(16,16,16,0.4);
      vertical-align:middle
  }

  .key-facts {
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 1em;
    font-weight: 200;
    color: #f5f5f5;
    max-width: 70vw;
    margin-left: 25vw;
    margin-top: 20vh;
  }

  .key-facts h3{
      text-align: left;
      position: relative;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 3.5vh;
      font-weight: 700;
      color: #f5f5f5;
      vertical-align:middle;
      padding-top: 2vh;
  }

  .key-facts p{
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 2.2vh;
    font-weight: 100;
    color: #f5f5f5;
    max-width: 70vw;
    margin-top: 10px;
  }

  .logo {
    filter: brightness(200%);
    padding: 0px;
    height: 8vh;
    min-height: 50px;
  }

  .nav-link {
    font-size: 1.4em;
  }

  .nav-link.active {
    color: orange;
  }

  .navIndicator {
    position:fixed;
    height: 100vh;
    width: 25vw;
    top:0;
    z-index: 200;
  }

  .navIndicator-container {
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);

  }

  .nav-dot {
    width: 100%;
    top:50%;
    bottom: 50%;
    height: 50px;
    padding-left: 5%;
    padding-right: 5%;
    align-content: center;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .navdot-dot {
    background-color: white;
    width: 20px;
    height:20px;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    left: 30px;
    transition: all 0.1s ease-in-out;
  }

  .navdot-title {
    display: none;
    margin-left: 80px;
    text-transform: uppercase;
    font-family: verdana;
    font-size: 1.5em;
    font-weight: 350;
    color: #f5f5f5;

    text-shadow: 1px 0.5px 1px #919191,
        1px 1px 1px #919191,
        1px 1.5px 1px #919191,
        1px 2px 1px #919191,
        1px 2.5px 1px #919191,
        1px 3px 1px #919191;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    white-space: nowrap;
  }


  .active-dot{
    width: 30px;
    transition: all 0.1s ease-in-out;
  }

  footer {
    color: lightGrey;
    width: 100vw;
    text-align: center;
    margin-top: -10vh;
    height: 10vh;
    position: relative;
    font-size: 1em;
  }
}

/* landscape */
@media screen and (min-width: 1024px) and (orientation: landscape){
  .mainHeader {
    margin-top: 3%;
    margin-left: 20%;
    margin-right: 5%;
    border-radius: 20px;
    padding: 0;
    padding-right: 30px;
    background-color: rgba(56,62,66, 0.8);
  }

  .mainHeader .container{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: 540px;
  }

  

  .card {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    alignItems: center;
    justifyContent: center;
    margin-left: 0;
  }

  .video_main {
    width: auto;
    height: 110vh;
    
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 1;
    overflow-y:  hidden;
  }
  
  .bgImage_main {
    width: auto;
    height: 110vh;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    -webkit-animation: blurAnimation 29s forwards;
    animation: blurAnimation 29s forwards;
    z-index: 0;
  }

  .video_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
    background-color: rgba(0,0,0,0.4)
  }

  .img_overlay {
    z-index: 100;
    position: absolute;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    align-content: center;
  }


  .title_video {
      text-align: center;
      position: relative;
      padding-left: 22%;
      padding-right: 10%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top: 30vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 5vh;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2);
      vertical-align:middle
  }

  .bgImage {
    height: 100vh;
    width: 100vw;
    position: relative;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
    filter: brightness(50%);
    overflow-y:  hidden;
  }

  .desc_overlay {
    background-color: #212529;
    opacity: 0.8;
    position: relative;
    left: 20%;
    top: 30vh;
    width:75%;
    height:60vh;
    border-radius: 5%;
  }

  .desc_overlay p{
    position: relative;
    padding: 5%;
    margin: 10px;
    color: white;
    font-size: 3vh;
  }

  .title_keyfacts {
      text-align: center;
      position: relative;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top:18vh;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 1.7em;
      font-weight: 700;
      color: #f5f5f5;
      text-shadow: 1px 0.5px 1px #919191,
          1px 1px 1px #919191,
          1px 1.5px 1px #919191,
          1px 2px 1px #919191,
          1px 2.5px 1px #919191,
          1px 3px 1px #919191,
          1px 3.5px 1px #919191,
          1px 4px 1px #919191,
          1px 4.5px 1px #919191,
          1px 5px 1px #919191,
      1px 9px 3px rgba(16,16,16,0.4),
      1px 11px 5px rgba(16,16,16,0.2),
      1px 12px 17px rgba(16,16,16,0.2),
      1px 15px 30px rgba(16,16,16,0.4);
      vertical-align:middle
  }

  .key-facts {
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 1em;
    font-weight: 200;
    color: #f5f5f5;
    max-width: 70vw;
    margin-left: 25vw;
    margin-top: 25vh;
  }

  .key-facts h3{
      text-align: left;
      position: relative;
      text-transform: uppercase;
      font-family: verdana;
      font-size: 3.5vh;
      font-weight: 700;
      color: #f5f5f5;
      vertical-align:middle;
      padding-top: 3vh;
  }

  .key-facts p{
    color: white;
    text-align: left;
    font-family: verdana;
    font-size: 2.8vh;
    font-weight: 100;
    color: #f5f5f5;
    max-width: 70vw;
    margin-top: -5px;
  }

  .logo {
    filter: brightness(200%);
    padding: 0px;
    height: 8vh;
    min-height: 50px;
  }

  .nav-link {
    font-size: 1.4em;
  }

  .nav-link.active {
    color: orange;
  }

  .navIndicator {
    position:fixed;
    height: 100vh;
    width: 25vw;
    top:0;
    z-index: 200;
  }

  .navIndicator-container {
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);

  }

  .nav-dot {
    width: 100%;
    top:50%;
    bottom: 50%;
    height: 50px;
    padding-left: 5%;
    padding-right: 5%;
    align-content: center;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .navdot-dot {
    background-color: white;
    width: 20px;
    height:20px;
    border-radius: 10px;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    left: 30px;
    transition: all 0.1s ease-in-out;
  }

  .navdot-title {
    display: none;
    margin-left: 80px;
    text-transform: uppercase;
    font-family: verdana;
    font-size: 1.5em;
    font-weight: 350;
    color: #f5f5f5;

    text-shadow: 1px 0.5px 1px #919191,
        1px 1px 1px #919191,
        1px 1.5px 1px #919191,
        1px 2px 1px #919191,
        1px 2.5px 1px #919191,
        1px 3px 1px #919191;
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    white-space: nowrap;
  }


  .active-dot{
    width: 30px;
    transition: all 0.1s ease-in-out;
  }

  footer {
    color: lightGrey;
    width: 100vw;
    text-align: center;
    margin-top: -10vh;
    height: 10vh;
    position: relative;
    font-size: 1em;
  }
}

.mainCont {
  background-color: black;
  overflow-y: hidden;
  overflow-y: none;
  left: 0;
}

.imprint {
  padding-top: 25vh;
  background-color: black;
  padding-bottom: 15vh;
  color: white;
}

.imprint p{
  padding-left: 3%;
}
.imprint h3{
  padding-left: 3%;
}
.imprint h4{
  padding-left: 3%;
}

.dsgvo {
  padding-top: 25vh;
  background-color: black;
  padding-bottom: 5vh;
  color: white;
  padding-right: 5vw;
  padding-left: 5vw;
}

.dsgvo p {
  padding-left: 3%;
}

.dsgvo h3 {
  padding-left: 3%;
}

.dsgvo h4 {
  padding-left: 3%;
}

.nav-link {
  padding-left: 10px;
}

.nav-link.active {
  color:orange;
}

.initials {
  color: orange;
}

@keyframes blurAnimation {
    0%   {-webkit-filter: blur(0px); /* Safari 6.0 - 9.0 */ filter: blur(0px);}
    90%  {-webkit-filter: blur(0px); /* Safari 6.0 - 9.0 */ filter: blur(0px);}
    100% {-webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */ filter: blur(5px);}
}
@-webkit-keyframes blurAnimation {
    0%   {-webkit-filter: blur(0px); /* Safari 6.0 - 9.0 */ filter: blur(0px);}
    90%  {-webkit-filter: blur(0px); /* Safari 6.0 - 9.0 */ filter: blur(0px);}
    100% {-webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */ filter: blur(5px);}
}
